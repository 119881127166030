<template>
  <div
    v-if="blok"
    id="headerSignupCard"
    class="signup-card flex flex-col sm:flex-row justify-between px-[32px] md:px-[64px] py-[32px] xl:py-0 gap-4 sm:gap-8 overflow-hidden xl:h-[76px]"
    :style="{ background: backgroundColor }"
  >
    <div class="flex flex-col items-center hidden xl:block">
      <StoryblokImage :image="blok.image" class="w-full h-full object-cover" />
    </div>
    <div class="flex flex-col justify-center text-canvas basis-2/3">
      <RichText :text="blok.content" />
    </div>
    <div class="flex flex-col justify-center items-center vvd-theme-alternate">
      <NuxtLink :href="blok.buttonLink" type="button">
        <VButton
          id="signup"
          :label="blok.buttonLabel"
          :appearance="isVonageBrand ? 'filled' : 'outlined'"
          :connotation="!isVonageBrand ? 'cta' : 'accent'"
          :icon="blok.buttonIcon"
          icon-trailing
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VButton } from '@vonage/vivid-vue';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';
import RichText from '@/components/rich-text/RichText.vue';
import type { PropType } from 'vue';
import { useBrandingStore } from '@/store/branding';
import { computed } from 'vue';
import type { HeaderSignUpCard } from '@developer-portal/storyblok';

const props = defineProps({
  blok: { type: Object as PropType<HeaderSignUpCard>, required: true },
});

const brandingStore = useBrandingStore();
const isVonageBrand = computed(() => brandingStore.data?.vendorId === 'vonage');

const backgroundColor = computed(() => {
  return props.blok.backgroundColor ? props.blok.backgroundColor : 'var(--dp-blog-landing-page-signup)';
});
</script>

<style lang="scss" scoped>
.signup-card {
  :deep(h4) {
    margin: 0;
    font: var(--vvd-typography-heading-5);
  }

  :deep(p) {
    margin: 0;
    font: var(--vvd-typography-base-light);
  }
}
</style>
